import { createSelector } from "@reduxjs/toolkit";

import { ORGANIZATION_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";
import { normalize, getOrganizationId, isJson } from "Libs/utils";
import { selectFeatureOrganizationsEnabled } from "Reducers/featureFlags/featureFlags.selectors";

import type {
  OrganizationEstimateComplex,
  Organization,
  APIObject
} from "@packages/client";
import type { AppDispatch, GetState, RootState } from "Store/configureStore";

export const LOAD_ORGANIZATION_START =
  "app/organizations/load_organization_start";
export const LOAD_ORGANIZATION_SUCCESS =
  "app/organizations/load_organization_success";
export const LOAD_ORGANIZATION_FAILURE =
  "app/organizations/load_organization_failure";

const CREATE_ORGANIZATION_START = "app/organizations/create_organization_start";
export const CREATE_ORGANIZATION_SUCCESS =
  "app/organizations/create_organization_success";
const CREATE_ORGANIZATION_FAILURE =
  "app/organizations/create_organization_failure";

const UPDATE_ORGANIZATION_START = "app/organizations/update_organization_start";
const UPDATE_ORGANIZATION_SUCCESS =
  "app/organizations/update_organization_success";
const UPDATE_ORGANIZATION_FAILURE =
  "app/organizations/update_organization_failure";

const DELETE_ORGANIZATION_START = "app/organizations/delete_organization_start";
const DELETE_ORGANIZATION_SUCCESS =
  "app/organizations/delete_organization_success";
const DELETE_ORGANIZATION_FAILURE =
  "app/organizations/delete_organization_failure";

const LOAD_ORGANIZATIONS_START = "app/organizations/load_organizations_start";
export const LOAD_MULTI_VENDOR_ORGANIZATIONS_SUCCESS =
  "app/organizations/load_multi_vendor_organizations_success";
export const LOAD_ORGANIZATIONS_SUCCESS =
  "app/organizations/load_organizations_success";
export const LOAD_REF_ORGANIZATIONS_SUCCESS =
  "app/organizations/load_more_organizations_success";
const LOAD_ORGANIZATIONS_FAILURE =
  "app/organizations/load_organizations_failure";

const LOAD_ORGANIZATIONS_FAILURE_CLEAR =
  "app/organizations/clear_organization_error";

const CLEAR_CREATED_ORGANIZATION =
  "app/organization/clear_created_organization";

const LOAD_ORGANIZATION_ESTIMATE_START =
  "app/organization/load_organization_estimate_start";
const LOAD_ORGANIZATION_ESTIMATE_SUCCESS =
  "app/organization/load_organization_estimate_success";
const LOAD_ORGANIZATION_ESTIMATE_FAILURE =
  "app/organization/load_organization_estimate_failure";

export const CreateOrganizationNamespace = "createOrganization";

export const updateOrganization = (
  organizationDescriptionId: string,
  organization: APIObject
) => {
  return async (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: UPDATE_ORGANIZATION_START });

    try {
      const organizationToUpdate = await getOrganization(
        getState,
        organizationDescriptionId
      );

      const newOrganization = await organizationToUpdate?.update(
        organization,
        organizationToUpdate.getLink("self")
      );

      dispatch({
        type: UPDATE_ORGANIZATION_SUCCESS,
        payload: organizationToUpdate?.updateLocal(newOrganization!.data),
        meta: { organizationDescriptionId }
      });

      if (organization?.name) {
        // Redirect to the new organization name. This function is just being
        // used on the organization settings page and it forces the reload of
        // that page. The strucutre should be different since this part should be
        // moved to the page itself but that requires a bigger refactor.
        window.location.replace(`/${organization.name}/-/settings`);
      }
    } catch (err) {
      logger(err, {
        action: "updateOrganization",
        organizationDescriptionId,
        organization
      });
      dispatch({
        type: UPDATE_ORGANIZATION_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const deleteOrganization = (organizationDescriptionId: string) => {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const organizationToDelete = await getOrganization(
        getState,
        organizationDescriptionId
      );
      dispatch({
        type: DELETE_ORGANIZATION_START,
        payload: organizationToDelete
      });
      await organizationToDelete?.delete();
      dispatch({
        type: DELETE_ORGANIZATION_SUCCESS,
        payload: organizationToDelete
      });
    } catch (err) {
      logger(err, {
        action: "deleteOrganization",
        organizationDescriptionId
      });
      dispatch({
        type: DELETE_ORGANIZATION_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const createOrganization = (organization: APIObject) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: CREATE_ORGANIZATION_START });
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const newOrganizationReference =
        await client.createOrganization(organization);

      const newOrganization: Organization =
        await newOrganizationReference.getEntity();

      dispatch({
        type: CREATE_ORGANIZATION_SUCCESS,
        payload: newOrganization
      });

      return newOrganization;
    } catch (err) {
      logger(err, {
        action: CreateOrganizationNamespace,
        organization
      });
      dispatch({
        type: CREATE_ORGANIZATION_FAILURE,
        error: true,
        payload: typeof err === "string" ? JSON.parse(err) : err
      });
    }
  };
};

const getOrganization = async (
  getState: GetState,
  descriptionId: string,
  dispatch?: AppDispatch
) => {
  const organization = organizationByDescriptionIdSelector(getState(), {
    organizationDescriptionId: descriptionId
  });
  const shouldReloadOrganisation =
    !organization || !(organization && "_links" in organization);

  if (shouldReloadOrganisation) {
    // Important to note that LOAD_REF_ORGANIZATIONS does not return full organisation response data
    // like _links for some permission operations on organisation. So if there is no organisation or
    // such organisation does not have _links it is likely that it is a response from ref organisation
    // lets load the full organisation to replace the ref organisation

    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    let organizationId = getOrganizationId(getState, descriptionId);

    // For admins, they may not have the organization in their /organizations response
    if (!organizationId) {
      organizationId = `name=${descriptionId}`;
    }

    try {
      const organization = await client.getOrganization(organizationId);
      // We have some especial organizations where the organization name is the
      // same as the organization id but in lowercase. The request above
      // attempts to find an organization by name but the endpoint is not case
      // sensitive. For those organizations console will have a hard time with
      // this and will fail to find the stored organization.
      // The catch side of this block will handle requesting the organization by
      // id and doing a clean redirect to the url with the organization name
      if (
        organization.id === descriptionId &&
        organization.name === descriptionId.toLowerCase()
      )
        throw new Error("unnamed organization");

      if (dispatch) {
        dispatch({
          type: LOAD_ORGANIZATION_SUCCESS,
          payload: organization
        });
      }

      return organization;
    } catch (err) {
      if (isJson(err)) {
        const errorAsJSON = JSON.parse(err);
        // The user needs either MFA or SSO to access the organization
        if (errorAsJSON.error === "insufficient_user_authentication") {
          // We need to patch the error since we don't have enough information
          // from the client.js
          errorAsJSON.status = 401;
          if (dispatch)
            dispatch({
              type: LOAD_ORGANIZATION_FAILURE,
              error: true,
              payload: errorAsJSON
            });
          return;
        }
      }
      try {
        // Lets try loading the organization as it is without assuming that is is the oranization name
        const organization = await client.getOrganization(
          descriptionId?.toUpperCase()
        );
        if (dispatch) {
          dispatch({
            type: LOAD_ORGANIZATION_SUCCESS,
            payload: organization
          });
        }

        // We don't use organisation actual Id in console
        // lets redirect to organisation name id after we
        // have succesfully fetched the organisation using it actual ID
        // Effectively makes withProjectRedirect HOC wrapper abselet
        location.pathname = location.pathname.replace(
          descriptionId,
          organization.name
        );

        return organization;
      } catch {
        return;
      }
    }
  }

  return organization;
};

export const loadOrganization = (descriptionId: string) => {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const organizationEnabled = selectFeatureOrganizationsEnabled(getState());
    if (!organizationEnabled) {
      return;
    }

    let organization = await getOrganization(getState, descriptionId, dispatch);

    if (organization) {
      return organization;
    }

    dispatch({ type: LOAD_ORGANIZATION_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      let organizationId = getOrganizationId(getState, descriptionId);

      // For admins, they may not have the organization in their /organizations response
      if (!organizationId) {
        organizationId = `name=${descriptionId}`;
      }

      try {
        organization = await client.getOrganization(organizationId);
      } catch (err) {
        if (isJson(err)) {
          const errorAsJSON = JSON.parse(err);
          // The user needs either MFA or SSO to access the organization
          if (errorAsJSON.error === "insufficient_user_authentication") {
            // We need to patch the error since we don't have enough information
            // from the client.js
            errorAsJSON.status = 401;
            dispatch({
              type: LOAD_ORGANIZATION_FAILURE,
              error: true,
              payload: errorAsJSON
            });
            return;
          }
        }
        throw "organization.notfound";
      }
      dispatch({
        type: LOAD_ORGANIZATION_SUCCESS,
        payload: organization
      });
    } catch (err) {
      if (![404, 403, 400].includes((err as { code: number }).code)) {
        logger(err, {
          action: "loadOrganization",
          id: descriptionId
        });
      }
      dispatch({ type: LOAD_ORGANIZATION_FAILURE, error: true, payload: err });
    }
  };
};

export const clearOrginaizationError = () => (dispatch: AppDispatch) => {
  dispatch({ type: LOAD_ORGANIZATIONS_FAILURE_CLEAR });
};

export const clearCreatedOrganization = () => ({
  type: CLEAR_CREATED_ORGANIZATION
});

export const loadOrganizationEstimate = (organization?: Organization) => {
  return async (dispatch: AppDispatch) => {
    if (!organization) return;

    dispatch({ type: LOAD_ORGANIZATION_ESTIMATE_START });
    try {
      const estimateResultCurrent = await organization.getEstimate({
        current_month: true,
        format: "complex"
      });
      const estimateResultFuture = await organization.getEstimate({
        format: "complex"
      });

      dispatch({
        type: LOAD_ORGANIZATION_ESTIMATE_SUCCESS,
        payload: {
          current: estimateResultCurrent,
          future: estimateResultFuture,
          organizationId: organization.name
        }
      });
    } catch (error) {
      if (![404, 403, 400].includes((error as { code: number }).code)) {
        logger(error, {
          action: "loadOrganizationEstimate",
          error
        });
      }
      dispatch({ type: LOAD_ORGANIZATION_ESTIMATE_FAILURE, payload: error });
    }
  };
};

const setError = (
  state: OrganizationState,
  action: { payload: unknown }
): OrganizationState => {
  let message = "";
  if (isJson(action.payload)) {
    const errors = JSON.parse(action.payload);
    message = errors.detail || errors.message;
  }

  return {
    ...state,
    errors: message,
    loading: false
  };
};

type OrganizationState = {
  loading?: boolean;
  multiVendorOrgs: Organization[];
  orgByDescriptionField: Record<string, Organization | undefined>;
  data: Record<string, Organization | undefined>;
  list: string[];
  errors: string | undefined;
  update: Record<string, boolean>;
  inflight: Record<string, boolean>;
  _errors: Record<string, unknown>;
  [CreateOrganizationNamespace]: Organization | undefined;
  organizationLoadingError: unknown;
  deleted: {
    [k: string]: Organization | boolean;
    loading: boolean;
  };
  organizationEstimate: Record<
    string,
    | {
        current: OrganizationEstimateComplex;
        future: OrganizationEstimateComplex;
      }
    | undefined
  >;
};

export const initialState: OrganizationState = {
  loading: false,
  orgByDescriptionField: {},
  multiVendorOrgs: [],
  data: {},
  list: [],
  errors: undefined,
  update: {},
  inflight: {},
  _errors: {},
  [CreateOrganizationNamespace]: undefined,
  organizationLoadingError: undefined,
  deleted: {
    loading: false
  },
  organizationEstimate: {}
};

type OrganizationAction =
  | {
      type: typeof UPDATE_ORGANIZATION_START;
    }
  | {
      type: typeof LOAD_ORGANIZATION_START;
    }
  | {
      type: typeof LOAD_ORGANIZATIONS_START;
    }
  | {
      type: typeof LOAD_REF_ORGANIZATIONS_SUCCESS;
      payload: Organization[];
    }
  | {
      type: typeof LOAD_MULTI_VENDOR_ORGANIZATIONS_SUCCESS;
      payload: Organization[];
    }
  | {
      type: typeof LOAD_ORGANIZATIONS_SUCCESS;
      payload: Organization[];
    }
  | {
      type: typeof UPDATE_ORGANIZATION_SUCCESS;
      payload: string;
    }
  | {
      type: typeof CREATE_ORGANIZATION_FAILURE;
      error: true;
      payload: unknown;
    }
  | {
      type: typeof CREATE_ORGANIZATION_START;
    }
  | {
      type: typeof CREATE_ORGANIZATION_SUCCESS;
      payload: Organization;
    }
  | {
      type: typeof CLEAR_CREATED_ORGANIZATION;
    }
  | {
      type: typeof LOAD_ORGANIZATION_SUCCESS;
      payload: Organization;
    }
  | {
      type: typeof UPDATE_ORGANIZATION_FAILURE;
      payload: unknown;
    }
  | {
      type: typeof LOAD_ORGANIZATIONS_FAILURE;
      payload: unknown;
    }
  | {
      type: typeof LOAD_ORGANIZATION_FAILURE;
      error: true;
      payload: unknown;
    }
  | {
      type: typeof LOAD_ORGANIZATIONS_FAILURE_CLEAR;
    }
  | {
      type: typeof DELETE_ORGANIZATION_SUCCESS;
      payload: Organization | undefined;
    }
  | {
      type: typeof DELETE_ORGANIZATION_START;
    }
  | {
      type: typeof DELETE_ORGANIZATION_FAILURE;
      error: true;
      payload: unknown;
    }
  | {
      type: typeof LOAD_ORGANIZATION_ESTIMATE_START;
    }
  | {
      type: typeof LOAD_ORGANIZATION_ESTIMATE_SUCCESS;
      payload: {
        current: OrganizationEstimateComplex;
        future: OrganizationEstimateComplex;
        organizationId: string;
      };
    }
  | {
      type: typeof LOAD_ORGANIZATION_ESTIMATE_FAILURE;
      payload: unknown;
    };

export default function organizationReducer(
  state = initialState,
  action: OrganizationAction
): OrganizationState {
  switch (action.type) {
    case UPDATE_ORGANIZATION_START:
    case LOAD_ORGANIZATION_START:
    case LOAD_ORGANIZATIONS_START:
      return { ...state, loading: true };
    case LOAD_REF_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...normalize(action.payload, "id") },
        orgByDescriptionField: {
          ...state.orgByDescriptionField,
          ...normalize(action.payload, ORGANIZATION_ID_FIELD)
        }
      };
    case LOAD_MULTI_VENDOR_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        multiVendorOrgs: action.payload
      };
    case LOAD_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        orgByDescriptionField: {
          ...state.orgByDescriptionField,
          ...normalize(action.payload, ORGANIZATION_ID_FIELD)
        },
        data: {
          ...state.data,
          ...normalize(action.payload, "id")
        },
        list: action.payload.map(organization => organization.id),
        errors: undefined
      };
    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        update: {
          ...state.update,
          [action.payload]: true
        },
        errors: undefined
      };
    case CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        inflight: {
          ...state.inflight,
          [CreateOrganizationNamespace]: false
        },
        _errors: {
          ...state._errors,
          [CreateOrganizationNamespace]: action.payload
        }
      };
    case CREATE_ORGANIZATION_START:
      return {
        ...state,
        inflight: {
          ...state.inflight,
          [CreateOrganizationNamespace]: true
        },
        _errors: {
          ...state._errors,
          [CreateOrganizationNamespace]: undefined
        }
      };
    case CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        orgByDescriptionField: {
          ...state.orgByDescriptionField,
          ...normalize([action.payload], ORGANIZATION_ID_FIELD)
        },
        data: {
          ...state.data,
          ...normalize([action.payload], "id")
        },
        inflight: {
          ...state.inflight,
          [CreateOrganizationNamespace]: false
        },
        [CreateOrganizationNamespace]: action.payload,
        list: [...state.list, action.payload.id],
        multiVendorOrgs: [...state.multiVendorOrgs, action.payload]
      };
    case CLEAR_CREATED_ORGANIZATION:
      return { ...state, [CreateOrganizationNamespace]: undefined };
    case LOAD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        orgByDescriptionField: {
          ...state.orgByDescriptionField,
          [action.payload[ORGANIZATION_ID_FIELD]]: action.payload
        },
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        },
        errors: undefined
      };
    case UPDATE_ORGANIZATION_FAILURE:
    case LOAD_ORGANIZATIONS_FAILURE:
      return setError(state, action);
    case LOAD_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        organizationLoadingError: action.payload
      };
    case LOAD_ORGANIZATIONS_FAILURE_CLEAR:
      return {
        ...state,
        organizationLoadingError: undefined
      };
    case DELETE_ORGANIZATION_SUCCESS:
      return action.payload
        ? {
            ...state,
            deleted: {
              ...state.deleted,
              [action.payload.id]: action.payload,
              loading: false
            }
          }
        : state;
    case DELETE_ORGANIZATION_START:
      return {
        ...state,
        deleted: {
          ...state.deleted,
          loading: true
        }
      };
    case DELETE_ORGANIZATION_FAILURE:
      return {
        ...state,
        ...setError(state, action),
        deleted: {
          ...state.deleted,
          loading: false
        }
      };
    case LOAD_ORGANIZATION_ESTIMATE_START:
      return { ...state, loading: true };
    case LOAD_ORGANIZATION_ESTIMATE_SUCCESS: {
      const { organizationId, current, future } = action.payload;

      return {
        ...state,
        loading: false,
        organizationEstimate: {
          ...state.organizationEstimate,
          [organizationId]: { current, future }
        }
      };
    }
    case LOAD_ORGANIZATION_ESTIMATE_FAILURE:
      return setError(state, action);
    default:
      return state;
  }
}

export const organizationSelector = (
  state: RootState,
  props: { organizationId?: string }
) =>
  props.organizationId
    ? state.organization.data[props.organizationId]
    : undefined;

export const organizationByDescriptionIdSelector = (
  state: RootState,
  props: { organizationDescriptionId?: string }
) =>
  props.organizationDescriptionId
    ? state.organization.orgByDescriptionField[props.organizationDescriptionId]
    : undefined;

export const organizationsSelector = (state: RootState) =>
  state.organization.data;

export const organizationStateSelector = (state: RootState) =>
  state.organization;
const getOrganizations = createSelector(
  organizationStateSelector,
  organization => Object.values(organization.orgByDescriptionField)
);

export const organizationEstimateSelector = createSelector(
  (state: RootState) => state.organization.organizationEstimate,
  (_: RootState, params: { organizationId: string }) => params.organizationId,
  (organizationEstimate, organizationId) =>
    organizationEstimate?.[organizationId]
);

export const organizationEstimateSubscriptionSelector = createSelector(
  organizationEstimateSelector,
  (_: RootState, params: { subscriptionId: string }) => params.subscriptionId,
  (estimate, subscriptionId) =>
    estimate?.current.subscriptions?.list?.find(
      subscription => `${subscription.license_id}` === subscriptionId
    )
);

export const organizationsMemberOrOwnerOfSelector = createSelector(
  organizationStateSelector,
  getOrganizations,
  (organizationState, organizations) =>
    organizations.filter(
      (organization): organization is Organization =>
        !!organizationState.list.find(id => organization?.id === id)
    )
);

export const organizationsMultiVendorOrgsSelector = createSelector(
  organizationStateSelector,
  organization => organization.multiVendorOrgs
);

export const canCreateProjectOrganizationsSelector = createSelector(
  getOrganizations,
  organizations =>
    organizations.filter(
      (organization): organization is Organization =>
        !!organization?.hasLink("create-subscription")
    )
);

export const organizationLoadingSelector = createSelector(
  organizationStateSelector,
  organization => organization.loading
);

export const organizationLoadingErrorSelector = (state: RootState) =>
  state.organization.organizationLoadingError;

export const organizationErrorsSelector = (state: RootState) =>
  state.organization.errors;

export const createdOrganizationSelector = (state: RootState) =>
  state.organization[CreateOrganizationNamespace];

export const deletedOrganizationSelector = (
  state: RootState,
  organizationId: string
) => state.organization.deleted[organizationId];

export const isLoadingDeleteOrganizationSelector = (state: RootState) =>
  state.organization.deleted.loading;
