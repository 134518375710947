import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import { getAddons, updateAddons, AddonState } from "./index";

const initialState: AddonState = {
  data: {},
  loadingAddons: false
};

const addonsReducer = createSlice({
  name: "app/organization/addons",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // GET ADD ONS
      .addCase(getAddons.pending, state => {
        state.loadingAddons = true;
        delete state.error;
      })
      .addCase(getAddons.fulfilled, (state, { meta, payload }) => {
        const { organizationId } = meta.arg;

        setDeep(state, ["data", organizationId], payload);
        state.loadingAddons = false;
      })
      .addCase(getAddons.rejected, (state, { payload }) => {
        state.loadingAddons = false;
        state.error = payload;
      })
      // UPDATE ADD ONS
      .addCase(updateAddons.pending, state => {
        state.loadingAddons = true;
        delete state.error;
      })
      .addCase(updateAddons.fulfilled, (state, { meta, payload }) => {
        const { organizationId } = meta.arg;
        setDeep(state, ["data", organizationId], payload);
        state.loadingAddons = false;
      })
      .addCase(updateAddons.rejected, (state, { payload }) => {
        state.loadingAddons = false;
        state.error = payload;
      });
  }
});

export default addonsReducer.reducer;
